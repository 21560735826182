import * as React from "react"

const ResourceGroup = (props) => {
    return (
        <p>{props.resourceGroup.name}</p>
    )
}

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ResourceGroupList = (props) => {
    console.log(props.azureResourceGroups.value);

    return (
        <div id="rgList">
            { props.azureResourceGroups.value.length > 0 ? props.azureResourceGroups.value.map((resourceGroup, i) => {
                return (
                    <ResourceGroup resourceGroup={resourceGroup} key={i} />
                )
            }) : null}
        </div>
    );
};
