import React, { Component } from "react"
import Chart from "react-apexcharts"
import Paper from '@mui/material/Paper'

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar",
          stacked: true
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        }
      },
      series: [
        {
          name: "rg 1 name",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        },
        {
          name: "rg 2 name",
          data: [80, 20, 15, 5, 49, 60, 20, 21]
        }
      ]
    };
  }

  render() {
    return (
      <div className="BarChart">
        <div className="row" style={{ paddingBottom: "5%" }}>
          <Paper elevation={3} sx={{ width: { sx: "100%", md: "75%" }, marginLeft: { sx: "0%", md: "10%"} }}>
            <div className="mixed-chart">
              <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                style={{ marginTop: "5%", paddingTop: "5%" }}
              />
            </div>
            <br/>
            <br/>
            <br/>
          </Paper>
        </div>
      </div>
    );
  }
}

export default BarChart;