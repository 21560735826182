import React, { useEffect, useState } from "react";
import { Link } from "gatsby"

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Loading } from "../components/loading";
import { ErrorComponent } from "../components/errorComponent";
import { ResourceGroupList } from "../components/azResourceGroup";
import BarChart from "../components/barChart"
import { getRefreshToken } from "../utils/commonApiCalls";
import { getAzureToken, getResourceGroups } from "../utils/azureApiCall";

import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const AzureContent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
      if (account && !graphData && inProgress === InteractionStatus.None) {
          const login = {
              ...apiRequest,
              account: account
          };
          
          instance.acquireTokenSilent(login).then((loginResponse) => {
            window.localStorage.setItem("authClaims", JSON.stringify(loginResponse.account.idTokenClaims));
            
            // Get ADO Token
            const clientId = loginResponse.idTokenClaims.aud;
            const tenantId = loginResponse.tenantId ? loginResponse.tenantId : "c4afa44d-3294-4c39-b063-0593f7ae69d4";

            let refreshToken = getRefreshToken(clientId);

            getAzureToken(refreshToken, clientId, tenantId).then((tokenResponse) => {
                let accessToken = tokenResponse.access_token;

                getResourceGroups(accessToken, "4740833f-77cf-41d9-a709-b6766787b03b")
                    .then(response => 
                        response.error ? console.error("Error getting resource groups", response.error) : setGraphData(response)
                    );
            }).catch((e) => {
                console.log(`Failed to get ADO token: ${e}`);
            });
          }).catch((e) => {
              if (e instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect(login);
              }
          });
      }
  }, [account, inProgress, instance, graphData]);

  return (
      <Paper>
          { graphData ? <ResourceGroupList azureResourceGroups={graphData} /> : 
          <Skeleton variant="rectangular" height={400} style={{ width: "100%" }} /> }
          
        <BarChart />
      </Paper>
  );
};

const AzurePage = () => {
    const authRequest = {
        ...apiRequest
    };
  
    return (
        <Layout>
            <Container maxWidth="xl">
                <Box sx={{ my: 4 }}>
                    <Seo title="Azure" />
                        <Typography variant="h4" component="h1" gutterBottom>
                            Azure
                        </Typography>
                    <MsalAuthenticationTemplate 
                        interactionType={InteractionType.Redirect} 
                        authenticationRequest={authRequest} 
                        errorComponent={ErrorComponent} 
                        loadingComponent={Loading}
                    >
                        <AzureContent />
                    </MsalAuthenticationTemplate>
                    <Link to="/">Go back to the homepage</Link>
                </Box>
            </Container>
        </Layout>
    )
};

export default AzurePage
