import { azureConfig } from "../authConfig";
import { getToken, getJSONRequest } from "./commonApiCalls";

export async function getAzureToken(refreshToken, clientId, tenantId) {
    return getToken(refreshToken, clientId, tenantId, "https%3A%2F%2Fmanagement.azure.com%2Fuser_impersonation%20openid%20profile")
}

export async function getSubscriptions(accessToken) {
    return getJSONRequest(accessToken, `${azureConfig.managementEndpoint}/subscriptions?api-version=2020-01-01`);
}

export async function getResourceGroups(accessToken, subscriptionId) {
    return getJSONRequest(accessToken, `${azureConfig.managementEndpoint}/subscriptions/${subscriptionId}/resourcegroups?api-version=2021-04-01`);
}
